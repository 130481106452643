import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import Img from 'gatsby-image';

const CardTitle = styled.h2`
  font-size: 2rem;
`;

const CardBody = styled.div`
  ${(props) =>
    props.footer &&
    css`
      padding-bottom: ${30 + 55.5}px;

      @media (min-width: 1200px) {
        padding-bottom: ${40 + 65.5}px;
      }
    `}
`;

const CardFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
`;

const StyledLink = styled(Link)`
  color: inherit;
  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
`;

function Card({ img, title, url, footer, children, className, ...props }) {
  const classNames = classnames(
    'uk-card uk-card-default uk-box-shadow-medium',
    className
  );
  return (
    <div className={classNames} {...props}>
      {img && (
        <div className="uk-card-media-top">
          {url ? (
            <Link to={url}>
              <Img fluid={img.childImageSharp.fluid} alt={title ? title : ''} />
            </Link>
          ) : (
            <Img fluid={img.childImageSharp.fluid} alt={title ? title : ''} />
          )}
        </div>
      )}
      <CardBody className="uk-card-body" footer={footer}>
        {title && (
          <CardTitle className="uk-card-title uk-text-bold">
            {url ? <StyledLink to={url}>{title}</StyledLink> : title}
          </CardTitle>
        )}
        {children}
      </CardBody>
      {footer && (
        <CardFooter className="uk-card-footer uk-text-right">
          {footer}
        </CardFooter>
      )}
    </div>
  );
}

Card.defaultProps = {
  img: null,
  title: null,
  url: null,
  footer: null,
};

Card.propTypes = {
  img: PropTypes.object,
  title: PropTypes.node,
  url: PropTypes.string,
  footer: PropTypes.node,
};

export default Card;

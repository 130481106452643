import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Section from '../components/Section';

import ProductList from '../views/ProductList';

function ProductsPage() {
  return (
    <Layout>
      <SEO title="Productos" />
      <Section
        title="Productos"
        sectionClass="uk-section-small"
        containerClass="uk-container-large"
      >
        <ProductList />
      </Section>
    </Layout>
  );
}

export default ProductsPage;

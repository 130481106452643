import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import Card from '../components/Card';
import { colors } from '../components/constants';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

function ProductList() {
  const { allProductsYaml } = useStaticQuery(PRODUCTS_QUERY);
  const productList = allProductsYaml.nodes;
  return (
    <div
      uk-grid=""
      className="uk-grid uk-grid-medium uk-grid-match uk-child-width-1-2@s"
    >
      {productList.map((node, i) => {
        return (
          <div key={i}>
            <Card
              img={node.gallery[0]}
              title={node.title}
              url={node.fields.slug}
              footer={
                <Container>
                  <Link
                    to={node.fields.slug}
                    className="uk-button uk-button-text"
                  >
                    Quiero saber más{' '}
                    <FontAwesomeIcon icon={faHeart} color={colors.red} />
                  </Link>
                  <OutboundLink
                    className="uk-button uk-button-text"
                    href={node.store_link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Comprar{' '}
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      color={colors.lightBlue}
                    />
                  </OutboundLink>
                </Container>
              }
            >
              <p>{node.short_description}</p>
            </Card>
          </div>
        );
      })}
    </div>
  );
}

const PRODUCTS_QUERY = graphql`
  query {
    allProductsYaml {
      nodes {
        title
        short_description
        store_link
        fields {
          slug
        }
        gallery {
          childImageSharp {
            fluid(quality: 100, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

export default ProductList;
